import React, { FunctionComponent } from 'react';
import { Link } from '../common-components/link/link.component';
import { FacebookIcon, HouzzIcon, InstagramIcon, PinterestIcon, YoutubeIcon } from '../svg/icons.component';
import { facebookHover, instagramHover, pinterestHover, youtubeHover, houzzHover } from './social-media-section.css';
import { SocialPlatformName } from './social-media-section.types';

const SOCIAL_MEDIA_DIVIDER = 'br b--theme-grey-light';

export type SocialMediaLinkProps = {
	name: SocialPlatformName;
	url: string;
	useDivider?: boolean;
	isLast: boolean;
};

const iconByName: Readonly<{ [platformName in SocialPlatformName]: React.ReactNode }> = {
	facebook: <FacebookIcon className={`theme-black ${facebookHover}`} />,
	instagram: <InstagramIcon className={`theme-black ${instagramHover}`} />,
	pinterest: <PinterestIcon className={`theme-black ${pinterestHover}`} />,
	youtube: <YoutubeIcon className={`theme-black ${youtubeHover}`} />,
	houzz: <HouzzIcon className={`theme-black ${houzzHover}`} />
};

export const SocialMediaLink: FunctionComponent<SocialMediaLinkProps> = ({ name, url, useDivider = false, isLast }) => {
	const icon = iconByName[name] || null;
	return (
		icon && (
			<Link
				url={url}
				ariaLabel={`Find us on ${name}`}
				className={`w-100 ${useDivider && !isLast && SOCIAL_MEDIA_DIVIDER}`}
				openInNewTab={true}>
				{icon}
			</Link>
		)
	);
};

export type SocialMediaSectionProps = {
	urls: Omit<SocialMediaLinkProps, 'useDivider' | 'isLast'>[];
	useDivider?: boolean;
	header?: string;
	className?: string;
};

export const SocialMediaSection: FunctionComponent<SocialMediaSectionProps> = ({ urls, useDivider = true, header, className }) => {
	return (
		<section className={`${className} w-100 w-50-ns mt0 pv3 b--theme-grey-light`} data-testid="socialMediaSection">
			{header && <h2 className="b db f5 mt0 ml3 mb3">{header}</h2>}
			<div className="flex items-center f3 f2-ns tc justify-around">
				{urls.map((url, index) => (
					<SocialMediaLink key={index} url={url.url} name={url.name} useDivider={useDivider} isLast={index === urls.length - 1} />
				))}
			</div>
		</section>
	);
};
